import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import User from '../../../admin/user';
export default {
  name: 'AddEditUserAccessRights',
  props: ['selectedUserData'],
  components: {
    DatePicker,
    User
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      editMode: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      index: 0,
      user: {
        id: 0,
        name: null,
        email: null
      },
      showUserModal: false,
      userDetailsData: [
        {
          fms_user_timesheet_uax_id: 0,
          user_id: 0,
          user_name: null,
          user_email: null,
          fms_prj_id: null,
          fms_prj_name: null,
          sales_agmt_hdr_id: 0,
          document_num: null,
          start_date: null,
          end_date: null
        }
      ],
      userDetailsFields: [
        {
          key: 'fms_prj_name',
          label: 'Project'
        },
        {
          key: 'document_num'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'customer_site_name'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    if (this.selectedUserData) {
      this.user = {
        id: this.selectedUserData.user_id,
        name: this.selectedUserData.user_name,
        email: this.selectedUserData.user_email
      };
      this.getFmsUserDetailById(this.selectedUserData.user_id);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save') {
          this.addEditFmsUserDetails();
        }
      }
    });
  },
  methods: {
    getFmsUserDetailById(userId) {
      this.loader = true;
      this.$store
        .dispatch('fmsTimesheet/getFmsUserDetailById', userId)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.userDetailsData = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditFmsUserDetails() {
      const userDetailsArray = this.userDetailsData.map(item => {
        return {
          fms_user_timesheet_uax_id: item.fms_user_timesheet_uax_id,
          fms_prj_id: item.fms_prj_id,
          fms_sales_agreement_hdr_id: item.sales_agmt_hdr_id,
          start_date: item.start_date,
          end_date: item.end_date
        };
      });
      const payload = {
        user_id: this.user.id,
        requestParams: { add_user_timesheet_uax: userDetailsArray }
      };
      this.$store
        .dispatch('fmsTimesheet/addEditFmsUserDetails', payload)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.editMode = false;
            this.responseMsg = response.data.message;
            this.getFmsUserDetailById(this.user.id);
          } else {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showHideUserModal(flag) {
      this.showUserModal = flag;
    },
    selectedUser(item) {
      this.user.id = item.user_id;
      this.user.name = item.name;
      this.user.email = item.user_email;
      this.showUserModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.index = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_TIMESHEET
      ) {
        this.parent_value_set_id = this.userDetailsData[index].fms_prj_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_TIMESHEET) {
        this.userDetailsData[this.index].fms_prj_id = item.value_set_dtl_id;
        this.userDetailsData[this.index].fms_prj_name = item.value_code;
        this.userDetailsData[this.index].sales_agmt_hdr_id = null;
        this.userDetailsData[this.index].document_num = null;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_TIMESHEET
      ) {
        this.userDetailsData[this.index].sales_agmt_hdr_id =
          item.value_set_dtl_id;
        this.userDetailsData[this.index].document_num = item.value_code;
        this.userDetailsData[this.index].customer_name = item.customer_name;
        this.userDetailsData[this.index].customer_site_name =
          item.customer_site_name;
      }
    },
    clearVsetValues(vsetCode, index) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_TIMESHEET) {
        this.userDetailsData[index].fms_prj_id = null;
        this.userDetailsData[index].fms_prj_name = null;
        this.userDetailsData[index].sales_agmt_hdr_id = null;
        this.userDetailsData[index].document_num = null;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_TIMESHEET
      ) {
        this.userDetailsData[index].sales_agmt_hdr_id = null;
        this.userDetailsData[index].document_num = null;
        this.userDetailsData[index].customer_name = null;
        this.userDetailsData[index].customer_site_name = null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.userDetailsData.push({
          fms_user_timesheet_uax_id: 0,
          user_id: this.user.id,
          user_name: this.user.name,
          user_email: this.user.email,
          fms_prj_id: null,
          fms_prj_name: null,
          sales_agmt_hdr_id: 0,
          document_num: null,
          start_date: null,
          end_date: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.userDetailsData.splice(index, 1);
      }
    },
    setIndex(index) {
      this.index = index;
    },
    disabledDates(date) {
      const refDate = this.userDetailsData[this.index].start_date;
      return commonHelper.disabledDates(date, refDate);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
