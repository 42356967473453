import commonHelper from '@/app/utility/common.helper.utility';
import AddEditUserAccessRights from './addEditUserAccessRights';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import User from '../../admin/user';

export default {
  name: 'UserAccessRightsTimesheet',
  components: {
    AddEditUserAccessRights,
    DatePicker,
    User
  },
  watch: {
    currentPage: function() {
      this.getFmsUsersDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFmsUsersDetails();
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      unsubscribe: null,
      payload: null,
      loader: false,
      showValueSetModal:false,
      showAddEditAccessRightModal: false,
      user: {
        id: null,
        text: null
      },
      project: {
        id: null,
        text: null
      },
      salesAgreement: {
        id: null,
        text: null
      },
      startDate: null,
      endDate: null,
      selectedUserData: null,
      showUserModal: false,
      data: [],
      fields: [
        {
          key: 'user_name'
        },
        {
          key: 'user_email'
        },
        {
          key: 'fms_prj_name',
          label: 'Project'
        },
        {
          key: 'document_num',
          label: 'Sales Agmt No.'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'customer_name',
        },
        {
          key: 'customer_site_name',
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.selectedUserData = null;
          this.showAddEditAccessRightModal = true;
        }
        if (actionName === 'download' && !this.showAddEditAccessRightModal) 
        {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'fmsTimesheet/getFmsUserDetail',
            'fms-timesheet-user-details',
            () => (this.loader = false)
          );
        }
      }
    });
  },

  methods: {
    getFmsUsersDetails() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        user_id: this.user.id,
        fms_sales_agreement_hdr_id: this.salesAgreement.id,
        fms_prj_id: this.project.id,
        start_date: this.startDate,
        end_date: this.endDate
      };
      this.loader = true;
      this.$store
        .dispatch('fmsTimesheet/getFmsUserDetail', this.payload)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.data = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    hideAddEditAccessRightModal() {
      this.showAddEditAccessRightModal = false;
    },
    showHideUserModal(flag) {
      this.showUserModal = flag;
    },
    selectedUser(item) {
      this.user.text = item.name;
      this.user.id = item.user_id;
      this.showUserModal = false;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_TIMESHEET
      ) {
        this.parent_value_set_id = this.project.id;
      } 
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_TIMESHEET) {
        this.project = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
        this.salesAgreement = { id: null, text: null };
       } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_TIMESHEET) {
        this.salesAgreement = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
      } 
    },
    clearVsetValues(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_TIMESHEET) {
        this.project = { id: null, text: null };
        this.salesAgreement = { id: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_TIMESHEET) {
        this.salesAgreement = { id: null, text: null };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    rowSelected(item) {
      this.selectedUserData = item;
      this.showAddEditAccessRightModal = true;
    },
    clearFilters() {
      this.user = { id: null, text: null };
      this.project = { id: null, text: null };
      this.salesAgreement = { id: null, text: null };
      this.startDate = null;
      this.endDate = null;
      this.data = [];
      this.totalRows = null;
      this.currentPage = 1;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
